import DateOnly from 'dateonly';

export async function getSpecialListByProps(storeId, props = {}, getStoreSpecials, sortBy, paramsMore) {
  if (!props.limit) props.limit = 50;
  const params = {
    specialsDate: (new DateOnly()),
    ...paramsMore,
    limit: +props.limit,
  };
  params.orderBy = sortBy;

  return getStoreSpecials(storeId, params);
}
